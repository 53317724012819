<template>
  <div>

    <!-- Media -->
    <!-- <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ userData.username }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Upload</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </b-media> -->

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Username -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Хэрэглэгчийн нэр"
            label-for="username"
          >
            <b-form-input
              id="username"
              v-model="userData.username"
              disabled
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Имэйл"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="userData.email"
              type="email"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <!-- <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Status"
            label-for="user-status"
          >
            <v-select
              v-model="userData.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-status"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: Role -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Хэрэглэгчийн эрх"
            label-for="user-role"
          >
            <v-select
              v-model="userData.roles"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :reduce="val => val.id"
              label="name"
              multiple
              :clearable="false"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Role -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Байгууллага"
            label-for="user-role"
          >
            <v-select
              v-model="userData.organization_name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="organizationOptions"
              label="name"
              @input="changeOrganization($event)"
              :reduce="val => val.name"
              :clearable="false"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Role -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Баг"
            label-for="user-role"
          >
            <v-select
              v-model="userData.team_name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="teamOptions"
              label="name"
              :reduce="val => val.name"
              :clearable="false"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>
        <!-- Company -->
        <b-col
          cols="12"
          md="4"
        >
            <b-form-group
              label="Багийн ахлагч"
              label-for="parent-id"
            >
              <v-select
                v-model="userData.parent"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="userOptions"
                :reduce="val => val.id"
                :clearable="false"
                label="username"
                input-id="parent-id"
                :selectable="val => val.id!==userData.id"
              />
            </b-form-group>
        </b-col>

      </b-row>
    </b-form>

    <b-row>
        <!-- <b-col
          cols="12"
          lg="6"
        >
          <organization-view-organization-timeline-card />
        </b-col> -->
        <b-col
          cols="12"
        >
          <user-view-user-children-card v-if="userData" :user-data="userData" :user-options="userOptions" :action="true"/>
        </b-col>
      </b-row>

    <!-- PERMISSION TABLE -->
    <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="permissionsData"
      >
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox :checked="data.value" />
        </template>
      </b-table>
    </b-card>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="editUser()"
    >
      Хадгалах
    </b-button>
    <b-button
      variant="outline-primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="resetPasswordUser()"
    >
      Нууц үг сэргээх
    </b-button>
    <b-button
      variant="outline-secondary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"

      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Буцаах
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import useUsersList from '../users-list/useUsersList'
import UserViewUserChildrenCard from '../users-view/UserViewUserChildrenCard.vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    UserViewUserChildrenCard
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    userOptions: {
      type: Array,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    organizationOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList()

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    return {
      resolveUserRoleVariant,
      avatarText,
      statusOptions,
      permissionsData,
      // organizationOptions,
      // teamOptions,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  data() {
    return {
      teamOptions: [],
    }
  },
  methods: {
    changeOrganization (param) {
      store.dispatch('app-user/fetchTeamsByFilter', { organization_name: param })
      .then(response => {
        this.teamOptions = response.data.response
      })
    },
    editUser () {
      console.log()
      store.dispatch('app-user/editUser', this.userData)
      .then(response => {
        if (response.data.success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    resetPasswordUser () {
      this.$swal({
        // title: 'Өнөөдрийн ажлуудаа өндөрлүүлэхүү?',
        text: "Сэргээх бол \"Сэргээх\" үгүй бол \"Болих\" дарна уу!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Сэргээх',
        cancelButtonText: 'Болих',
        customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        console.log()
        store.dispatch('app-user/resetPasswordUser', this.userData)
        .then(response => {
          if (response.data.success) {
            this.$toast({
            component: ToastificationContent,
            props: {
            title: response.data.message,
            icon: 'BellIcon',
            variant: 'success',
            },
            })
            } else {
            this.$toast({
            component: ToastificationContent,
            props: {
            title: response.data.message,
            icon: 'BellIcon',
            variant: 'danger',
            },
            })
            }
        })
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
